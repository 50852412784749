$primary-color: #3498db;
$secondary-color: #2c3e50;
$text-color: #fff;

$link-hover-color: darken($primary-color, 10%);


.contact {

    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    .contact__container {

        padding: 3rem;
        border-radius: 10px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        color: $text-color;
        width: 100%;
        max-width: 800px;

        h1 {
            font-size: 3rem;
            margin-bottom: 2rem;
        }

        .contact__info {
            p {
                font-size: 2rem;
                margin: 0.5rem 0;

                strong {
                    color: $primary-color;
                }
            }
        }

        .contact__links {
            margin-top: 2rem;

            a {
                display: inline-flex;
                align-items: center;
                font-size: 2rem;
                color: $text-color;
                background-color: $primary-color;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                margin: 1rem 0.5rem;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $link-hover-color;
                }

                svg {
                    margin-right: 0.5rem;
                }
            }
        }
    }
}


@media (max-width: 768px) {
    .contact__container {
        padding: 2rem;
    }

    .contact__info p {
        font-size: 1rem;
    }

    .contact__links a {
        font-size: 1rem;
        padding: 0.4rem 0.8rem;
    }
}