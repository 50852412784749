.photoGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 90px 10vw;
    background-color: #111;



    img {
        flex-grow: 1;
        max-width: calc(25% - 50px);
        margin-bottom: 50px;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }
}


.last-row-one {
    justify-content: center;
}


.last-row-two {
    justify-content: center;
    gap: 20px;
}

@media (max-width: 1024px) {
    .photoGallery img {
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 600px) {
    .photoGallery img {
        max-width: calc(100% - 20px);
    }
}




.ReactModal__Overlay {
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ReactModal__Content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 80vh;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #a32;
        border: 2px solid;
        border-radius: 4px;
        padding: 5px 10px;
        cursor: pointer;
        font-size: 3rem;
        text-transform: uppercase;
        transition: 0.3s;

        &:hover {
            background-color: white;
        }
    }


    .modal-controls {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        pointer-events: none;
        transform: translateY(-50%);


        button {
            background: rgba(0, 0, 0, 0.5);
            color: white;
            border: none;
            font-size: 2.5rem;
            padding: 10px;
            cursor: pointer;
            transition: 0.3s;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: all;


        }


        .prev {
            position: absolute;
            top: -3vh;
            left: 20px;

        }


        .next {
            position: absolute;
            top: -3vh;
            right: 20px;

        }

        .close {
            position: absolute;
            color: red;
            left: 50%;
            top: -4vh;
            transform: translate(-50%, -50%);


        }
    }

    .arrow {
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
    }

    .left {
        border-right: 2rem solid white;
    }

    .right {
        border-left: 2rem solid white;
    }



    @media (max-width: 800px) {

        .modal-controls {
            .close {
                top: 100%;


            }

            .prev {
                top: 90%;
            }

            .next {
                top: 90%;


            }
        }


    }
}