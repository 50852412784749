.aboutMe {
    margin-top: 5vh;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    h1 {
        color: white;
        width: 100%;
        text-align: center;
        font-size: 3rem;
        letter-spacing: 3px;
        margin-bottom: 5vh;
    }

    .leftSide {
        width: 40%;

        div {
            position: fixed;
            height: 70vh;

            img {

                height: 90%;
                border: 2px solid goldenrod;
            }

            div {
                color: white;
                text-align: center;
                height: 10%;
            }
        }

    }

    .rightSide {
        width: 40%;

        p {
            color: #ccc;
            font-size: 2rem;

        }

        .bigImg {


            position: relative;

            background-color: black;
            transform: scale(1.7);


            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            z-index: 6;

            img {

                width: 30%;

            }
        }

        .photos+p {
            padding: 2vh 0;
        }

        .photos {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding-top: 3vh;

            h2 {
                width: 100%;
                color: goldenrod;
                font-size: 2rem;
                text-align: center;
                padding-bottom: 2vh;
            }

            img {
                width: 30%;
            }
        }
    }

    .photos img {
        width: 100%;
        height: auto;
        max-width: 200px;

        cursor: pointer;
        transition: transform 0.3s ease;
    }

    .photos img:hover {
        transform: scale(1.1);

    }

    .fullscreen-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);

        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

    }

    .fullscreen-img {
        max-width: 90%;
        max-height: 90%;
        object-fit: contain;

        box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);

    }

    .fullscreen-overlay:hover {
        cursor: pointer;
    }
}

@media(max-width:1000px) {
    .aboutMe {


        .leftSide {
            width: 80%;
            text-align: center;
            margin-bottom: 5vh;

            div {
                position: static;
            }

        }

        .rightSide {
            width: 80%;
        }
    }
}

@media(max-width:550px) {
    .aboutMe {


        .leftSide {
            width: 100%;
            text-align: center;
            margin-bottom: 5vh;

            img {
                position: static;
                margin: 0 auto;
            }
        }

        .rightSide {
            width: 90%;
        }
    }
}

@media(max-width:500px) {
    .aboutMe {


        .leftSide {
            width: 100%;
            text-align: center;
            margin-bottom: 5vh;

            img {
                position: static;
                height: 50vh;
            }
        }

        .rightSide {
            width: 90%;
        }
    }
}

@media(max-width:350px) {
    .aboutMe {


        .leftSide {
            width: 100%;
            text-align: center;
            margin-bottom: 5vh;

            img {
                position: static;
                height: 40vh;
            }
        }

        .rightSide {
            width: 90%;
        }
    }
}

@media (min-width: 1400px) {
    .aboutMe {
        padding: 0 10vw;
    }
}