@import url('https://fonts.googleapis.com/css2?family=Protest+Guerrilla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Moon+Dance&display=swap');



.portfolio {
    width: 100%;

    .wrap {
        border-top: 2px solid #333;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 80px;
        width: 80vw;
        margin: 0 auto;

        .category {
            flex: 1 1 calc(50% - 10px);
            display: flex;
            flex-direction: column;
            margin-bottom: 7vh;
            max-width: 45%;
            transition: 0.3s;
            transform: rotate(0);
            border: none;




            a {
                display: flex;
                flex: 1;
                text-decoration: none;
                flex-direction: row;

                img {
                    flex-shrink: 0;
                    width: 25%;
                    height: auto;
                }

                .label {
                    flex: 1;
                    background-color: rgba(150, 150, 150, 0.6);
                    color: white;
                    font-size: 5rem;
                    letter-spacing: 1px;
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: "Moon Dance", cursive;

                    p {

                        margin: 0;
                        z-index: 3;
                    }

                    .shape {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 0;
                        border-right: 30vw solid transparent;
                        border-bottom: 10vw solid rgba(17, 17, 17, 0.7);
                        z-index: 1;
                    }
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .wrap {
            width: 90vw;
        }

        .category {
            flex: 1 1 calc(50% - 10px);
        }
    }

    @media (max-width: 768px) {
        .wrap {
            width: 100vw;
            flex-direction: column;


            .category {
                flex: 1 1 100%;
                width: 100%;
                max-width: 100%;
            }
        }


    }

    @media (max-width: 480px) {
        .wrap {
            width: 100vw;
            flex-direction: column;

            .category {
                flex: 1 1 100%;
                width: 100%;
            }
        }


    }

    @media (min-width: 1024px) {
        .wrap {
            .category {
                &:hover {
                    transform: rotate(20deg);
                    border: 5px solid wheat
                }
            }
        }
    }
}