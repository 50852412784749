.contener {


    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

div.blackout {
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

}