@import url('https://fonts.googleapis.com/css2?family=Moon+Dance&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Whisper&display=swap');



.Navbar-home {
    height: 100vh;
    color: white;
    position: relative;
    background-position: center center;
    transition: background-image 1s ease-in-out;



    background-size: cover;

    background-repeat: no-repeat;

    .wrap {
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
    }


    nav {
        display: flex;
        justify-content: flex-end;

        font-size: 2.5rem;
        padding-top: 2.5rem;


        .nameLogo {
            display: flex;
            justify-content: space-between;



            p {
                width: 30%;
            }

            img {
                display: block;
                height: 10vh;
            }

        }

        ul {
            display: flex;
            justify-content: space-around;
            list-style: none;

            li {
                margin: 1rem;
            }

            a {
                padding: 1rem;
                font-size: 2rem;
                text-decoration: none;
                color: white;
                transition: 0.3s;

                &:hover {
                    color: black;
                    background-color: white;
                }
            }
        }

        .hamburger {
            display: none;
            position: absolute;
            right: 10vw;
            top: 15vh;
            z-index: 3;
            cursor: pointer;
            flex-direction: column;
            justify-content: space-around;
            height: 2rem;
            width: 2rem;

            span {
                display: block;
                height: 0.25rem;
                width: 100%;
                background-color: white;
                transition: all 0.3s;
            }
        }



        @media (max-width: 768px) {
            .hamburger {
                display: flex;
            }

            ul {
                display: none;
                flex-direction: column;
                position: absolute;
                top: 0%;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.8);
                text-align: center;
                z-index: 2;

                li {
                    margin: 1rem 0;
                }

                &.open {
                    display: flex;
                }
            }
        }
    }

    .text {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;



        h5 {
            font-size: 2.5rem;
            margin-bottom: 1.5rem;
            font-style: italic;

            font-family: "Roboto Condensed", sans-serif;
            font-optical-sizing: auto;
            font-weight: 600;
            font-style: italic;
            letter-spacing: 2px;

        }

        h3 {
            font-size: 4.5rem;
            margin-bottom: 1.5rem;
            letter-spacing: 0.3rem;
            font-family: "Roboto Condensed", sans-serif;
            font-optical-sizing: auto;
            font-weight: 600;
            font-style: italic;

        }

        h6 {
            display: block;

            font-size: 2.5rem;
            font-family: "Roboto Condensed", sans-serif;

            font-optical-sizing: auto;
            font-weight: 600;
            font-style: italic;

        }

    }

    .photoMenu {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -150%);
        display: flex;
        justify-content: space-around;
        width: 40vw;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: black;
            margin-right: 4rem;
            cursor: pointer;
            transition: 0.3s;
            transform: scale(1);

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    @media (max-width: 1100px) {
        .photoMenu {
            width: 100vw;
        }
    }

    @media (max-width: 400px) {
        .photoMenu {
            display: none;
        }
    }


}

.Navbar-subPage {
    height: 10vh;
    color: white;

    background-position: center center;
    transition: background-image 1s ease-in-out;



    background-size: cover;


    background-repeat: no-repeat;

    .wrap {
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
    }


    nav {
        display: flex;
        justify-content: flex-end;
        font-size: 2.5rem;
        padding-top: 2rem;


        .nameLogo {
            display: flex;
            padding: 1rem;


        }

        ul {
            display: flex;
            justify-content: space-around;
            list-style: none;

            li {
                margin: 1rem;
            }

            a {
                padding: 1rem;
                text-decoration: none;
                color: white;
                transition: 0.3s;

                &:hover {
                    color: black;
                    background-color: white;
                }
            }
        }

        .hamburger {
            display: none;
            position: absolute;
            right: 10vw;
            top: 15vh;
            z-index: 3;
            cursor: pointer;
            flex-direction: column;
            justify-content: space-around;
            height: 2rem;
            width: 2rem;

            span {
                display: block;
                height: 0.25rem;
                width: 100%;
                background-color: white;
                transition: all 0.3s;
            }
        }

        @media (max-width: 768px) {
            .hamburger {
                display: flex;
            }

            ul {
                display: none;
                flex-direction: column;
                position: absolute;
                top: 0%;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.8);
                text-align: center;
                z-index: 2;

                li {
                    margin: 1rem 0;
                }

                &.open {
                    display: flex;
                }
            }
        }

    }


}

.Navbar-portfolio {
    position: fixed;
    height: 100vh;
    width: 100%;
    color: white;

    background-position: center center;
    transition: background-image 1s ease-in-out;



    background-size: cover;


    background-repeat: no-repeat;

    .wrap {
        background-color: rgba(0, 0, 0, 0.6);
        height: 100%;
    }


    nav {
        display: flex;
        justify-content: space-around;
        font-size: 2.5rem;
        padding-top: 2rem;



        .nameLogo {
            display: flex;
            padding: 1rem;


        }

        ul {
            display: flex;
            justify-content: space-around;
            list-style: none;

            li {
                margin: 1rem;
            }

            a {
                padding: 1rem;
                text-decoration: none;
                color: white;

            }


        }

        .hamburger {
            display: none;
            position: absolute;
            right: 10vw;
            top: 10vh;
            z-index: 103;
            cursor: pointer;
            flex-direction: column;
            justify-content: space-around;
            height: 2rem;
            width: 2rem;



            span {
                display: block;
                height: 0.25rem;
                width: 100%;
                background-color: white;
                transition: all 0.3s;
            }
        }

        @media (max-width: 768px) {
            .hamburger {
                display: flex;
            }

            ul {
                display: none;

                flex-direction: column;
                position: absolute;
                top: 0%;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.8);
                text-align: center;
                z-index: 99;

                li {
                    margin: 1rem 0;
                }

                &.open {
                    display: flex;
                }
            }
        }

    }
}