@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 15px;
    font-family: "Roboto", sans-serif;

}

a {
    color: black;
}


#logo {
    position: absolute;
    display: block;
    height: 40vh;
    top: -10vh;
    left: 5%;
    z-index: 2;
    max-width: 80vw;
}

@media (max-width: 1400px) {
    html {
        font-size: 13px;


    }
}

@media (max-width: 1150px) {
    html {
        font-size: 11px;


    }
}

@media (max-width: 1000px) {
    html {
        font-size: 9px;


    }
}

@media (max-width: 920px) {
    html {
        font-size: 8px;


    }
}